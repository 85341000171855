import React from "react";

import { ISurveyOptionProps } from "../../../models/survey/survey-question-option-props.model";

import {
    squareOption,
    labelText,
    squareInput,
    squareWrapper,
    imgWrapper,
    imgSquare,
    squareIcon,
    labelTextWrapper,
} from "./survey-option-square.module.scss";

import Checked from "../../../assets/images/svg/checked-new.svg";
import { input } from "../../atoms/form/input.module.scss";
import { FieldHookConfig, useField } from "formik";
import SurveyHint from "./survey-hint";

const SurveyOptionSquare = (props: ISurveyOptionProps) => {
    const { option, inputType, questionId } = props;
    const { id, label, imgUrl, hint } = option;

    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
        value: id.toString(),
    };

    const [field] = useField(optionConfig);

    return (
        <label className={squareOption}>
            <input className={squareInput} {...field} type={inputType} />
            <div className={squareWrapper}>
                <Checked className={squareIcon} />

                <div className={imgWrapper}>
                    {imgUrl && <img className={imgSquare} src={imgUrl} alt="" />}
                </div>

                {label && (
                    <div className={labelTextWrapper}>
                        <p className={labelText}>{label}</p>
                        {hint && <SurveyHint hint={hint} />}
                    </div>
                )}
            </div>
        </label>
    );
};

export default SurveyOptionSquare;
