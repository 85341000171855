// extracted by mini-css-extract-plugin
export var buttonGenerate = "user-panel-module--button-generate--KPA6x";
export var introduction = "user-panel-module--introduction--frxEU";
export var leftWrapper = "user-panel-module--left-wrapper--YSyKO";
export var logoBottom = "user-panel-module--logo-bottom--rz8lk";
export var ratio = "user-panel-module--ratio--8m09-";
export var rightWrapper = "user-panel-module--right-wrapper--9i2iU";
export var textContent = "user-panel-module--text-content--1drHY";
export var titleText = "user-panel-module--title-text--gI2Qx";
export var userPanel = "user-panel-module--user-panel--ma4n9";
export var welcomeImage = "user-panel-module--welcome-image--zxE+a";