import actionName from "../action-creator";
import { GENERATOR_PRODUCTS } from "./generator-products.reducer";
import {
    setGeneratorProductsAction,
    restoreGeneratorProductsInitialStateAction,
    types,
} from "./generator-products.actions";
import endpoints from "../../config/endpoints";
import { actions, sendApiRequest } from "../api/api.actions";
import { statusIdle } from "../ui/ui.actions";
import {
    selectGeneratorProductsSessionId,
    selectGeneratorProductsStatus,
} from "./generator-products.selectors";
import { getGeneratorParams } from "../../utils/get-generator-params";
import { setAlert } from "../alerts/alerts.actions";

const generatorProductsMiddleware = (state) => (next) => (action) => {
    const { dispatch, getState } = state;
    const { type, payload, meta } = action;


    next(action);

    let fetchConfig;

    switch (type) {
        case actionName(GENERATOR_PRODUCTS, types.GET):
            if (selectGeneratorProductsStatus(getState()) !== "loading") {
                const sessionId = selectGeneratorProductsSessionId(getState());
                let isLoggedIn = true;
                try {
                    // console.log('state.getState()', state.getState());
                    isLoggedIn = state.getState()['[USER]'].userId>0;
                } catch (e) {
                }
                const params = {
                    ...(sessionId ? { sessionId } : {}),
                    ...getGeneratorParams(payload),
                };
                fetchConfig = {
                    url: isLoggedIn ? endpoints.stylizations.products : endpoints.stylizations.productsdemo,
                    method: "GET",
                    withCredentials: true,
                    params,
                }
                meta.useAlternateApi = true;
                dispatch(sendApiRequest(fetchConfig, { ...meta, previousPayload: payload }));
            }
            break;
        case actionName(GENERATOR_PRODUCTS, `${types.GET} ${actions.API_SUCCESS}`):
            const productPayload = {
                sessionId: payload.sessionId,
                sessionGuid: payload.sessionGuid,
                items: payload.items,
            };
            dispatch(setGeneratorProductsAction(productPayload));
            break;
        case actionName(GENERATOR_PRODUCTS, `${types.GET} ${actions.API_FAIL}`):
            if (payload.data?.messages?.length) {
                payload.data.messages.forEach((message) => {
                    dispatch(
                        setAlert(
                            {
                                type: "error",
                                content: message.content,
                            },
                            meta
                        )
                    );
                });
            }
            break;
        case actionName(GENERATOR_PRODUCTS, types.CLEAR):
            dispatch(statusIdle({ actionName: types.CLEAR, entity: GENERATOR_PRODUCTS }));
            dispatch(restoreGeneratorProductsInitialStateAction());
            break;
        default:
            break;
    }
};

export default generatorProductsMiddleware;
