import React from "react";
import {
    surveyProgress,
    container,
    bar
} from "./survey-progress.module.scss";

export interface ISurveyHintProps {
    filledQuestions: number;
    allQuestions: number;
}
const SurveyProgress = ({ filledQuestions, allQuestions }: ISurveyHintProps) => {
    return (
        <div className={surveyProgress}>
            <div className={container}>
                <div className={bar} style={{ width: `${(filledQuestions / allQuestions) * 100}%` }}></div>
                {/* <span className="progress-text">{filledQuestions} / allQuestions</span> */}
            </div>
        </div>
    );
};

export default SurveyProgress;