import React from "react";
import { ISurveyQuestion } from "../../../models/survey/survey-question.model";
import {
    surveyHintWrapper,
    questionMark,
    hintContainer,
    hintHeading,
    surveyHint,
} from "./survey-hint.module.scss";
import WarningIcon from "../../../assets/images/svg/warning2.svg";

export interface ISurveyHintProps {
    hint: ISurveyQuestion["hint"];
}

const SurveyHint = ({ hint }: ISurveyHintProps) => {
    return (
        <div className={`${surveyHintWrapper}`}>
            {/* <div className={questionMark}>?</div> */}
            <WarningIcon />
            <div className={hintContainer}>
                <p className={hintHeading}>Wskazówka</p>
                <div className={surveyHint}>{hint}</div>
            </div>
        </div>
    );
};

export default SurveyHint;
