module.exports = [
    // {
    //     labelKey: "Witaj",
    //     to: "ACCOUNT_DASHBOARD",
    // },
    {
        labelKey: "Twoje stylizacje",
        to: "ACCOUNT_STYLIZATIONS",
    },
    {
        labelKey: "Twoja ankieta",
        to: "ACCOUNT_SURVEY_SESSION",
    },
    {
        labelKey: "Dane konta",
        to: "ACCOUNT_DATA",
    },
    {
        labelKey: "Zmiana hasła",
        to: "ACCOUNT_PASS_CHANGE",
    },
    {
        labelKey: "Newsletter",
        to: "ACCOUNT_NEWSLETTER",
    },
];
