import React from "react";
import { RouteComponentProps } from "@reach/router";

import { title } from "./survey-question-view.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";

import Section from "../../../components/molecules/section";
import AccountTitle from "../atoms/account-title";
import SurveyQuestionEdit from "../../../components/organisms/survey/survey-question-edit";

type TSurveySessionQuestionProps = {
    questionId: string;
    surveySessionId: string;
} & RouteComponentProps;

export default function SurveyQuestionView(props: TSurveySessionQuestionProps) {
    const { questionId, surveySessionId } = props;

    return (
        <div>
             {/* className={mainGrid} */}
            {/* <Section>
                <AccountTitle className={title} />
            </Section> */}
            <Section>
                <SurveyQuestionEdit questionId={questionId} sessionId={surveySessionId} />
            </Section>
        </div>
    );
}
