import React, { useState } from "react";
import Moment from "react-moment";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { useDispatch } from "react-redux";
import { Link } from "gatsby";

import {
    actions,
    buttonAction,
    container,
    link,
    content,
    date,
    iconEdit,
    iconDelete,
    image,
    imageBox,
    imageRatio,
    images,
    nameBox,
    nameText,
    occasionText,
    card,
    item,
    list,
    titleWrapper,
    productCardContent,
    headerCard,
    titleContent,
    moreContent,

} from "./stylization-card.module.scss";
import TrashCanIcon from "../../../assets/images/svg/trash-can.svg";
import DrawIcon from "../../../assets/images/svg/draw.svg";
import { IStylization } from "../../../models/stylization.model";
import { EModal } from "../../../models/modal.model";
import { useModalContext } from "../../../contexts/modal.context";
import { getAbsolutePath } from "../../utility/routes.utility";
import {
    deleteSingleStylizationAction,
    updateSingleStylizationAction,
} from "../../../redux/single-stylization/single-stylization.actions";

import ButtonEdit from "../../../components/atoms/button-edit";
import StylizationEditForm, { IStylizationEditFormProps } from "./stylization-edit-form";
import ProductCard from "../../generator/molecules/product-card";
import moment from "moment";

interface IStylizationCardProps {
    className?: string;
    stylization: IStylization;
    NameTag?: React.ElementType;
}

const StylizationCard: React.FC<IStylizationCardProps> = ({
    className = "",
    stylization,
    NameTag = "h2",
}) => {
    const { t } = useI18next();
    const dispatch = useDispatch();
    const { stylizationId, name, occasion, createdAt, products } = stylization;
    const [editMode, setEditMode] = useState(false);
    const { addModal } = useModalContext();

    const LinkTag = editMode ? "div" : Link;

    const handleEdit = () => {
        setEditMode((prevEditMode) => !prevEditMode);
    };

    const handleSubmit: IStylizationEditFormProps["onSubmit"] = (values) => {
        dispatch(updateSingleStylizationAction({ data: values, stylizationId }));
    };

    const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
        console.log('handleDelete', event);
        const target = event.target as HTMLElement;
        const button = target.closest('button');
        if (button instanceof HTMLButtonElement) {
            event.preventDefault();
            event.stopPropagation();
            addModal({
                key: EModal.CONFIRMATION,
                props: {
                    onConfirm() {
                        dispatch(deleteSingleStylizationAction(stylizationId));
                    },
                    title: t("stylization.delete.confirm.title"),
                    content: t("stylization.delete.confirm.text"),
                },
            });
        }
    };

    return (
        <div className={`${container} ${className}`}>
            <LinkTag
                className={link}
                to={`${getAbsolutePath("GENERATOR_STYLIZATION")}/${stylizationId}`}
            >
                <div className={list}>
                    {products.map((product) => {
                        //product.imgUrl = "https://api.ff-test.site/get-file/123982_44d69cb651154da380a07e3b97fb4326";
                        return (
                            <div key={product.productId} className={item}>
                                <ProductCard isTablet={false} readonly={true} product={product} className={card} productLength={products.length} />

                            </div>
                        );
                    })}
                </div>
                <div className={titleWrapper}>
                    <div className={productCardContent}>
                        <div className={headerCard}>
                            <div>{moment(stylization.createdAt * 1000).format('DD-MM-YYYY')}</div>
                            | <div className={titleContent}>{stylization.name}</div>
                        </div>
                        <h3></h3>
                        <div className={actions}>
                            <div></div>
                            {/* <ButtonEdit className={buttonEdit} onClick={handleEdit} /> */}
                            {/* <button className={buttonAction} onClick={handleEdit} >
                            <DrawIcon className={iconEdit} />
                        </button> */}
                            <button className={buttonAction} onClick={(event) => handleDelete(event)}>
                                <TrashCanIcon className={iconDelete} />
                            </button>
                        </div>
                    </div>
                </div>
            </LinkTag>
        </div>
    );
};

export default StylizationCard;
