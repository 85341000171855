// extracted by mini-css-extract-plugin
export var arrowBackProfile = "generator-start-fields-module--arrow-back-profile--Upkbm";
export var backProfile = "generator-start-fields-module--back-profile--x7aRR";
export var container = "generator-start-fields-module--container--d5Fby";
export var formBottom = "generator-start-fields-module--form-bottom--dCBeS";
export var formBreak = "generator-start-fields-module--form-break--TmoGB";
export var formButton = "generator-start-fields-module--form-button--N-oKq";
export var formTop = "generator-start-fields-module--form-top--gMEBL";
export var header = "generator-start-fields-module--header--JsDT2";
export var imgBox = "generator-start-fields-module--img-box--bULOk";
export var inputTop = "generator-start-fields-module--input-top--RN5fx";
export var moreContent = "generator-start-fields-module--more-content--nyYRA";
export var occasionItem = "generator-start-fields-module--occasion-item--axdw5";
export var occasionWrapper = "generator-start-fields-module--occasion-wrapper--REBbv";
export var postCardContent = "generator-start-fields-module--post-card-content--XCq8f";
export var ratio = "generator-start-fields-module--ratio--pRSbc";
export var stylizationHeader = "generator-start-fields-module--stylization-header--AdV7r";
export var textBackProfile = "generator-start-fields-module--text-back-profile--UnUfu";
export var title = "generator-start-fields-module--title--3j8e6";
export var titleContent = "generator-start-fields-module--title-content--R8Qw2";
export var titleWrapper = "generator-start-fields-module--title-wrapper--ar7V6";