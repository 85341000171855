import React from "react";
import { useField } from "formik";

import {
    container,
    switcher,
    text,
    arrowLeft,
    arrowRight,
    arrowButton,
    labelText,
} from "./switch-input.module.scss";
import Arrow from "../../../assets/images/svg/expand-button.svg";
import { TOption } from "../../../models/option.model";

interface ISwitchInputProps {
    className?: string;
    name: string;
    options: TOption[];
    label?: string;
    productLength?: number;
    showLabel?: boolean;
}

const SwitchInput: React.FC<ISwitchInputProps> = ({
    className = "",
    name,
    options,
    label,
    productLength,
    showLabel = false,
}) => {
    const [field, meta, helpers] = useField(name);

    const handleChange = (direction: "prev" | "next") => () => {
        const currValue = meta.value;

        const currValueIndex = options.findIndex((option) => {
            const optionValue = option.value || option.id;
            return currValue === optionValue;
        });

        if (currValueIndex < 0) return;

        if (direction === "prev") {
            let prevValueIndex = currValueIndex - 1;
            if (prevValueIndex < 0) {
                prevValueIndex = options.length - 1;
            }
            const prevValue = options[prevValueIndex].value || options[prevValueIndex].id;
            helpers.setValue(prevValue);
        }

        if (direction === "next") {
            let nextValueIndex = currValueIndex + 1;
            if (nextValueIndex >= options.length) {
                nextValueIndex = 0;
            }
            const nextValue = options[nextValueIndex].value || options[nextValueIndex].id;
            helpers.setValue(nextValue);
        }
    };

    return (
        <div className={`${container} ${className}`}>
            {showLabel && <p className={labelText}>{label}</p>}
            <div className={switcher}>
                <button type="button" className={arrowButton} onClick={handleChange("prev")}>
                    <Arrow className={arrowLeft} />
                </button>

                <label {...field} className={text}>
                    {getLabel(options, meta.value)}
                </label>

                <button type="button" className={arrowButton} onClick={handleChange("next")}>
                    <Arrow className={arrowRight} />
                </button>
            </div>
        </div>
    );
};

const getLabel = (options: TOption[], fieldValue: string | number) => {
    return options.find((option) => {
        return (option.value || option.id) === fieldValue;
    })?.label;
};

export default SwitchInput;
