import React from "react";

import {
    noTextOption,
    noTextInput,
    noTextWrapper,
    imgNoText,
    noTextIcon,
} from "./survey-option-square-no-text.module.scss";

import Checked from "../../../assets/images/svg/checked-new.svg";
import { input } from "../../atoms/form/input.module.scss";
import { FieldHookConfig, useField } from "formik";
import { ISurveyOptionProps } from "../../../models/survey/survey-question-option-props.model";

const SurveyOptionSquareNoText = (props: ISurveyOptionProps) => {
    const { option, inputType, questionId } = props;
    const { id, imgUrl } = option;

    const optionConfig: FieldHookConfig<any> = {
        name: questionId.toString(),
        type: inputType,
        value: id.toString(),
    };

    const [field] = useField(optionConfig);

    return (
        <label className={noTextOption}>
            <input className={noTextInput} {...field} type={inputType} />
            <div className={noTextWrapper}>
                <Checked className={noTextIcon} />
                {imgUrl && <img className={imgNoText} src={imgUrl} alt="" />}
            </div>
        </label>
    );
};

export default SurveyOptionSquareNoText;
