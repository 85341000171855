import { IProduct } from "./product.model";
import { TOption } from "./option.model";

export type TProductCategoryGroup = "top" | "bottom" | "outerwear" | "overall";

export enum EGeneratorFilterKeys {
    colors = "colors",
    patterns = "patterns",
    occasion = "occasion",
    style = "style",
    priceTop = "priceTop",
    priceBottom = "priceBottom",
    priceOuterwear = "priceOuterwear",
    priceOverall = "priceOverall",
}

export type TGeneratorFilters = Record<EGeneratorFilterKeys, TOption[]>;

export interface IGeneratorProductsSetPayload {
    sessionId: number | null;
    sessionGuid: string;
    items: IProduct[];
}

export type TBlockedProduct = Pick<IProduct, "productId" | "categoryGroup">;

export interface IGeneratorFormValues {
    name: string;
    blockedProducts: TBlockedProduct[];
    [EGeneratorFilterKeys.colors]: string[] | number[];
    [EGeneratorFilterKeys.patterns]: string[] | number[];
    [EGeneratorFilterKeys.occasion]: string | number;
    [EGeneratorFilterKeys.style]: string | number;
    [EGeneratorFilterKeys.priceTop]: string | number;
    [EGeneratorFilterKeys.priceBottom]: string | number;
    [EGeneratorFilterKeys.priceOuterwear]: string | number;
    [EGeneratorFilterKeys.priceOverall]: string | number;
}

export type TGeneratorRequestParams = Partial<
    Record<EGeneratorFilterKeys | TProductCategoryGroup | "blockedProducts", string | number>
>;
