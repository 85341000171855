import React from "react";

import {
    categoriesBar,
    categoryPill,
    current,
    previous,
    pageCounter,
    surveyNav,
    linkLogo,
    logo,
} from "./survey-fill-nav.module.scss";
import { ISurveyCategory } from "../../../models/survey/survey-session.model";
import random from "../../../utils/random-string";
import Logo from "../../atoms/logo";
import Link from "../../atoms/link";
import SurveyProgress from "./survey-progress";

export interface ISurveyFillNavProps {
    categories: ISurveyCategory[];
    pageCount: number;
    currentIndex: number;
    currentPageCategoryId: number;
}

export default function SurveyFillNav({
    categories = [],
    pageCount,
    currentIndex,
    currentPageCategoryId,
}: ISurveyFillNavProps) {
    if (categories.length === 0 && !currentIndex && !pageCount) {
        return null;
    }

    return (
        <div>
            <div className={surveyNav}>
                <Link to="/" className={linkLogo}>
                    <Logo className={logo} color={"white"} type={"sygnet"} />
                </Link>
                <div className={categoriesBar}>
                    {categories.map((category, index) => {
                        return (
                            <span
                                key={`${category.categoryId}-${random()}`}
                                className={genCssClass(currentPageCategoryId, category.categoryId)}
                            >
                                {category.name}
                            </span>
                        );
                    })}
                </div>

                {/* <div className={pageCounter}>
                    {currentIndex} / {pageCount}
                </div> */}
            </div>
        </div>
    );
}

const genCssClass = (currentId: number, id: number) => {
    let cssClass = categoryPill;
    if (currentId - 1 > id) {
        cssClass = `${categoryPill} ${previous}`;
    }
    if (currentId === id) {
        cssClass = `${categoryPill} ${current}`;
    }
    return cssClass;
};
