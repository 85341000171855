import { Link } from "gatsby";
import React from "react";
import Button from "../atoms/button";
import Wand from '../../assets/images/svg/wand_2.svg';

import {
    buttonWand,
    buttonLink,
    buttonNew,
    buttonIcon,
} from './button-wand.module.scss';


interface IButtonWandProps {
    className?: '',
    text?: string,
    htmlType?: 'button' | 'submit' | 'reset',
    withLink?: boolean,
    link?: string,
}

const ButtonWand: React.FC<IButtonWandProps> = ({
    className = '',
    text = '',
    htmlType = 'button',
    withLink = true,
    link = '/generator/wyczaruj-stylizacje'
}) => {
    return (
        <div className={`${className} ${buttonWand}`}>
            {withLink && <Link className={buttonLink} to={link}>
                <Button htmlType={htmlType} className={buttonNew} color="primary" type="filled">
                    <div className={buttonIcon}><Wand></Wand></div>
                    <>{text}</>
                </Button>
            </Link>}
            {!withLink && <Button htmlType={htmlType} className={buttonNew} color="primary" type="filled">
                    <div className={buttonIcon}><Wand></Wand></div>
                    <>{text}</>
                </Button>}

        </div>
    );
};


export default ButtonWand;