import actionName from "../action-creator";
import { types } from "./generator-products.actions";

const GENERATOR_PRODUCTS = `[GENERATOR_PRODUCTS]`;

const initialState = {
    sessionId: null,
    sessionGuid: null,
    items: [],
};

function generatorProductsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case actionName(GENERATOR_PRODUCTS, types.SET):
            return payload;
        case actionName(GENERATOR_PRODUCTS, types.RESTORE_INITIAL):
            return initialState;
        default:
            return state;
    }
}

export { initialState, GENERATOR_PRODUCTS };

export default generatorProductsReducer;
