import React from "react";

import { container, header, content } from "./generator-layout.module.scss";

import MainLayout from "./main-layout";
import HeaderSurvey from "../components/organisms/headers/headerSurvey";
import { breakpoints } from "../config/breakpoints";
import useMediaQuery from "../hooks/use-media-query";

interface IGeneratorLayoutProps {
    className?: string;
}

const GeneratorLayout: React.FC<IGeneratorLayoutProps> = ({ children }) => {
    const isTablet = useMediaQuery(breakpoints.tablet);
    return (
        <MainLayout
            containerClassName={container}
            HeaderComponent={<HeaderSurvey className={header} />}
            hasPaddingTop={true}
            footerVisible={!isTablet}
        >
            <div className={content}>{children}</div>
        </MainLayout>
    );
};

export default GeneratorLayout;
