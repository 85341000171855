import React, { useEffect } from "react";
import { Formik, FormikValues } from "formik";
import { useSelector } from "react-redux";

import {
    actionsBar,
    actionsContent,
    alerts,
    header,
    arrow,
    button,
    buttonTextIcon,
    formikWrapper,
    globalError,
    infoLink,
    infoLinkBox,
    pageTitleWrapper,
    surveyWrapper,
    backButton,
    sendButton,
    mainElement
} from "./survey-page.module.scss";
import Arrow from "../../../assets/images/svg/arrow.svg";
import { config } from "../../../config";
import { ISurveyCategory } from "../../../models/survey/survey-session.model";
import { EModal } from "../../../models/modal.model";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import useFillSurvey from "../hooks/use-fill-survey";
import useUser from "../../auth/hooks/use-user";
import { useModalContext } from "../../../contexts/modal.context";
import getTranslationKey from "../../../utils/get-translation-key";
import { genInitialValues, genValidationSchema } from "../../../utils/survey.utils";
import { selectFillSurveyShowPopup } from "../../../redux/survey-fill/survey-fill.selectors";

import MainLayout from "../../../layouts/main-layout";
import Section from "../../../components/molecules/section";
import Button from "../../../components/atoms/button";
import SurveyFillNav from "../../../components/molecules/survey/survey-fill-nav";
import SectionWrapper from "../../../components/molecules/section-wrapper";
import SurveyQuestion from "../../../components/organisms/survey/survey-question";
import Loader from "../../../components/atoms/loader";
import Title from "../../../components/atoms/title";
import FormikForm from "../../../components/hoc/formik-form";
import Error from "../../../components/atoms/form/error";
import AlertList from "../../../components/organisms/alert-list";
import HeaderSurvey from "../../../components/organisms/headers/headerSurvey";
import SurveyProgress from "../../../components/molecules/survey/survey-progress";
import useMediaQuery from "../../../hooks/use-media-query";
import { breakpoints } from "../../../config/breakpoints";

const { statusMap } = config;

export default function SurveyPage() {
    const { t } = useI18next();
    const {
        pageIndex,
        pageCount,
        categories,
        currentPage,
        isLoading,
        pageQuestions,
        goNextPage,
        goPrevPage,
    } = useFillSurvey();
    const { status, isLoggedIn } = useUser();
    const showPopup = useSelector(selectFillSurveyShowPopup);
    const { addModal } = useModalContext();

    const surveyTranslations = getTranslationKey("survey");
    const isTablet = useMediaQuery(breakpoints.tablet);

    const showLoader = isLoading || (status === statusMap.loading && isLoggedIn);

    const nextPage = (values: FormikValues) => {
        goNextPage(values);
    };

    useEffect(() => {
        if (!showPopup) return;
        addModal({ key: EModal.SURVEY_REGISTER, canBeClosed: false });
    }, [showPopup]);

    return (
        <MainLayout
            HeaderComponent={null}
            footer={true}
            isSurvey={true}
            className={mainElement}
        >
            <AlertList className={alerts} />

            {showLoader && <Loader position={"fixed"} />}

            {/* <SectionWrapper bgColor={"blue-light"} className={surveyWrapper}>
                <Section> */}
            <div className={surveyWrapper}>
                <SurveyFillNav
                    categories={categories as ISurveyCategory[]}
                    currentIndex={pageIndex}
                    currentPageCategoryId={currentPage?.category.id}
                    pageCount={pageCount}
                />
                {isTablet && <SurveyProgress filledQuestions={pageIndex} allQuestions={pageCount} />}
                {currentPage?.title && (
                    <Title containerClassName={pageTitleWrapper} size={"medium"}>
                        {currentPage.title}
                    </Title>
                )}
                <Formik
                    initialValues={genInitialValues(pageQuestions)}
                    validationSchema={genValidationSchema(pageQuestions, t)}
                    onSubmit={nextPage}
                    enableReinitialize={true}
                >
                    {(formik) => {
                        return (
                            <FormikForm
                                formik={formik}
                                successMessage={""}
                                fieldsetClassName={formikWrapper}
                                loaderType={"none"}
                                showErrors={false}
                            >
                                {Array.isArray(pageQuestions) &&
                                    pageQuestions.map((question, index) => {
                                        return (
                                            <SurveyQuestion
                                                key={`question-${question.questionId}`}
                                                question={question}
                                                multiQuestion={pageQuestions.length > 1}
                                            >
                                                {index === pageQuestions.length - 1 && (
                                                    <>
                                                        <Button
                                                            className={`${backButton}`}
                                                            onClick={goPrevPage}
                                                            disabled={pageIndex === 1 || isLoading}
                                                        >
                                                            {t(surveyTranslations("question.prev"))}
                                                        </Button>
                                                        <Button
                                                            className={sendButton}
                                                            type="filled"
                                                            htmlType="submit"
                                                            disabled={!formik.isValid || isLoading}
                                                        >
                                                            {t(surveyTranslations("question.next"))}
                                                        </Button>
                                                    </>)}
                                            </SurveyQuestion>
                                        );
                                    })}

                                {formik.status === statusMap.fail && formik.errors.global && (
                                    <Error className={globalError}>
                                        {formik.errors.global}
                                    </Error>
                                )}
                                {currentPage?.infoUrl && (
                                    <p className={infoLinkBox}>
                                        {t(surveyTranslations("link.text"))}
                                        {` `}
                                        <a
                                            className={infoLink}
                                            href={currentPage.infoUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {t(surveyTranslations("link.label"))}
                                        </a>
                                    </p>
                                )}
                                {/* <div className={actionsBar}>
                                        <div className={actionsContent}>

                                        </div>
                                    </div> */}
                            </FormikForm>
                        );
                    }}
                </Formik>
                {!isTablet && <SurveyProgress filledQuestions={pageIndex} allQuestions={pageCount} />}
                {/* </Section>
            </SectionWrapper> */}
            </div>
        </MainLayout>
    );
}
