// extracted by mini-css-extract-plugin
export var actionsBar = "survey-page-module--actions-bar--U-kP6";
export var actionsContent = "survey-page-module--actions-content--MX8sR";
export var alerts = "survey-page-module--alerts--qdhzn";
export var arrow = "survey-page-module--arrow--jJAXJ";
export var backButton = "survey-page-module--back-button--gBYnn";
export var button = "survey-page-module--button--QQP11";
export var buttonTextIcon = "survey-page-module--button--text-icon--duldA";
export var formikWrapper = "survey-page-module--formik-wrapper--xgaoJ";
export var globalError = "survey-page-module--global-error--QSpW2";
export var infoLink = "survey-page-module--info-link--Hb-Fd";
export var infoLinkBox = "survey-page-module--info-link-box--TYufp";
export var mainElement = "survey-page-module--main-element--MFOwz";
export var pageTitleWrapper = "survey-page-module--page-title-wrapper--qBsMh";
export var sendButton = "survey-page-module--send-button--SfubJ";
export var surveyWrapper = "survey-page-module--survey-wrapper--MDW5p";