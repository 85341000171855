// extracted by mini-css-extract-plugin
export var actions = "stylization-card-module--actions--uP8tD";
export var buttonAction = "stylization-card-module--button-action--OZH66";
export var card = "stylization-card-module--card--t1E71";
export var container = "stylization-card-module--container--9QOG0";
export var content = "stylization-card-module--content--KlU5U";
export var date = "stylization-card-module--date--NonLi";
export var header = "stylization-card-module--header--SvEW2";
export var headerCard = "stylization-card-module--header-card--HJlws";
export var iconDelete = "stylization-card-module--icon-delete--irZF3";
export var iconEdit = "stylization-card-module--icon-edit--78DW-";
export var image = "stylization-card-module--image--PYx-7";
export var imageBox = "stylization-card-module--image-box--Ycefr";
export var imageRatio = "stylization-card-module--image-ratio--d2+9b";
export var images = "stylization-card-module--images--r0LLU";
export var item = "stylization-card-module--item--1kxXY";
export var link = "stylization-card-module--link--XTj26";
export var list = "stylization-card-module--list--oRNjP";
export var moreContent = "stylization-card-module--more-content--f2t6y";
export var nameBox = "stylization-card-module--name-box--9HcLx";
export var nameText = "stylization-card-module--name-text--5xhbG";
export var occasionText = "stylization-card-module--occasion-text--UjAdW";
export var productCardContent = "stylization-card-module--product-card-content--BPkj9";
export var titleContent = "stylization-card-module--title-content--yqNsC";
export var titleWrapper = "stylization-card-module--title-wrapper--qor-8";