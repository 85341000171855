import React from "react";

import { subtitleText } from "./account-title.module.scss";

import Title from "../../../components/atoms/title";

interface IAccountTitleProps {
    className?: string;
}

const AccountTitle: React.FC<IAccountTitleProps> = ({ className = "", children }) => {
    return (
        <Title
            subtitle=""
            className={className}
            subtitleClassName={subtitleText}
            align="center"
            fontStyle="normal"
            Tag="h1"
            size="medium"
        >
            {children}
        </Title>
    );
};

export default AccountTitle;
