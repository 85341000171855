import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useSelector } from "react-redux";
import { RouteComponentProps } from "@reach/router";
import { getImage, ImageDataLike } from "gatsby-plugin-image";

import {
    userPanel,
    titleText,
    introduction,
    ratio,
    logoBottom,
    welcomeImage,
    leftWrapper,
    rightWrapper,
    textContent,
    buttonGenerate,
} from "./user-panel.module.scss";
import { mainGrid } from "../../../styles/grid.module.scss";
import { selectUserProfile } from "../../../redux/user/user.selectors";

import Section from "../../../components/molecules/section";
import RatioImage from "../../../components/atoms/ratio-image";
import AccountTitle from "../atoms/account-title";
import TwoBoxSection from "../../../components/organisms/sections/common/two-box-section";
import ButtonWand from "../../../components/molecules/button-wand";
import { getAbsolutePath } from "../../utility/routes.utility";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

export default function UserPanel(props: RouteComponentProps) {
    const profile = useSelector(selectUserProfile);
    const { t } = useI18next();

    const title = profile ? `Cześć ${profile?.firstName}!` : "Cześć!";

    return (
        <>
            <TwoBoxSection
                className={userPanel}
                classNameLeftWrapper={leftWrapper}
                classNameRightWrapper={rightWrapper}
                title={title}
                text={<div className={textContent}> <p>
                    Oto twoje konto, tutaj znajdziesz wszystkie stylówki oraz informacje
                    dotyczące Twojego konta.
                </p>
                    <p>Miłej zabawy!</p>
                    <ButtonWand className={buttonGenerate} link={getAbsolutePath("GENERATOR_NEW_STYLIZATION")} text={t("stylizations.button.generate")}></ButtonWand>
                </div>} />
        </>
    );
}

const query = graphql`
    query {
        welcomePhotoLandscape: file(relativePath: { eq: "welcome-photo-landscape.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 80, layout: FULL_WIDTH)
            }
        }
    }
`;
