// extracted by mini-css-extract-plugin
export var actions = "stylizations-module--actions--iXOed";
export var bar = "stylizations-module--bar--OSUyg";
export var button = "stylizations-module--button--gpb9g";
export var buttonDelete = "stylizations-module--button-delete--rtWha";
export var buttonEdit = "stylizations-module--button-edit--q1+ZY";
export var buttonGenerate = "stylizations-module--button-generate--cffh3";
export var container = "stylizations-module--container--KzVIt";
export var error = "stylizations-module--error--xKcQ-";
export var icon = "stylizations-module--icon--nEZAD";
export var iconDelete = "stylizations-module--icon-delete--qqSwn";
export var info = "stylizations-module--info--GEa9M";
export var list = "stylizations-module--list--lMncQ";
export var section = "stylizations-module--section--41e4B";
export var stylizationItem = "stylizations-module--stylization-item--sKIkL";
export var title = "stylizations-module--title--NNVhn";