import React, { useEffect, useState } from "react";
import Link from "../../atoms/link";

import {
    header,
    logo,
    menuButton,
    scrolled as scrolledClass,
    hiding,
    alerts,
    buttonsWrapper,
    hidden,
} from "./header.module.scss";
import MenuIcon from "../../../assets/images/svg/menu.svg";
import MENU_LINKS from "../../../config/menu-links";
import { IMenuLinksElement } from "../../../models/menu-links-element.model";

import Logo from "../../atoms/logo";
import Menu from "../../molecules/menu";
import Button from "../../atoms/button";
import MenuVertical from "../menu-vertical";
import AlertList from "../alert-list";
import useMenuWithPermissions from "../../../hooks/use-menu-with-permissions";
import { useLocation } from "@reach/router";

export interface IHeaderProps {
    color: "white" | "black";
    scrolled?: boolean;
    className?: string;
    hideLogo?: boolean;
    renderCustomButtons?: (buttons: React.ReactNode, headerProps: IHeaderProps) => React.ReactNode;
}

export default function Header(props: IHeaderProps) {
    const { color, scrolled, className, hideLogo, renderCustomButtons } = props;
    const { pathname } = useLocation();
    const [showMenu, setMenuShow] = useState(false);

    const cssClass = `${header} ${scrolled ? scrolledClass : ""} ${className}`;
    const colorCssClass = !scrolled ? color : "white";

    const menuLinks = useMenuWithPermissions(MENU_LINKS as IMenuLinksElement[]);

    const handleMenuShow = () => setMenuShow(!showMenu);

    useEffect(() => {
        console.log("location: ", pathname);
        console.log("color: ", color);
    }, [pathname]);

    const defaultButtons = (
        <>
            <Button
                className={`${menuButton} ${showMenu ? hiding : ""}`}
                smallPaddingHeight
                onClick={handleMenuShow}
            >
                <MenuIcon />
            </Button>
        </>
    );

    return (
        <header className={cssClass}>
            <Link to="/" className={`${logo} ${hideLogo ? hidden : ""}`}>
                <Logo color={colorCssClass} />
            </Link>
            <Menu navItems={menuLinks} color={colorCssClass} />
            <div className={buttonsWrapper}>
                {renderCustomButtons ? renderCustomButtons(defaultButtons, props) : defaultButtons}
            </div>
            {showMenu ? (
                <MenuVertical navItems={menuLinks} onClose={() => setMenuShow(false)} />
            ) : null}

            <AlertList className={alerts} />
        </header>
    );
}
